import { useEffect } from 'react';
import { Container } from '@mui/material';
import { setup } from "../../resources/setup/setup";
import Section from '../Section/Section';

const MainPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container style={{ paddingTop: 50 }}>
      {setup.map((section, index) => (
        !section.hidden && <Section key={index} section={section} />
      ))}
    </Container>
  );
}

export default MainPage;
