import React from "react";
import { makeStyles } from '@mui/styles';
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const useStyles = makeStyles(() => ({
  box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  },
  text: {
    fontFamily: "'Montserrat', sans-serif"
  }
}));

export const Page404 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Typography className={classes.text} align="center" alignContent="center">Looks like we've misplaced whatever you were looking for...</Typography>
    </Box>
  );
};

export default Page404;
