import { Grid, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Box } from "@mui/system";
// import ProjectCard from "../ProjectCard/ProjectCard";
import ProjectCardV2 from "../ProjectCard/ProjectCardV2";

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '1.75rem',
    fontWeight: 'bold',
    lineHeight: 1.2
  }
}));

const Section = ({ section }) => {
  const classes = useStyles();

  return (
    <Box>
      <Grid container>
        <Typography className={classes.title} mt={4} mb={2} variant="h3" align={"center"}>{section.longName}</Typography>
      </Grid>
      <Grid container justify="center" alignItems="center" spacing={4}>
        {section.hasProjects && section.projects.map((project, index) => (
          !project.hidden && <ProjectCardV2 key={index} project={project} />
        ))}
      </Grid>
    </Box>
  );
}

export default Section;
