export const setup = [
  {
    longName: "Software",
    shortName: "Software",
    hasProjects: "false",
    hasLink: "false",
    projects: [
      {
        longName: "Unsmog-It",
        shortName: "Games",
        link: "/software/unsmog-it",
        imagePath: "/software/unsmog-it/images/title.jpg",
        download: {
          buttonLabel: "Download installer",
          path: "https://tudormaerean.ro/software/unsmog-it-installer"
        },
        data: [
          { warn: "Please be aware that the installer is quite large (66,5 Mb). Do not download over a mobile connection if you can avoid it!" },
          { h3: "Intro" },
          { p: "After creating the Arena proof of concept game, I wanted to implement a fully functional game. This meant creating game menus, a core game experience, a game state machine, all of the required data (2D/3D) etc." },
          { p: "The main points of the project:" },
          {
            ol: [
              "Get an even deeper understanding of a game engine (Unity).",
              "Create usable game assets, static and animated, integrate audio elements in the game and the UI.",
              "Generate an end-to-end gaming experience.",
              "Provide the user with a standard installer.",
            ]
          },
          { h3: "Story" },
          { p: "In the game, you are the mayor of a very small village that has to handle the local polution. As the game progresses" },
          { p: "Being a very quick game to play, the user has 2 minutes to handle the situation. As the game progresses, more and more cars will pass through your village, increasing pollution." },
          { p: "You are able to handle what type of vehicles are willing to access your roads, through a pollution tax spread across 3 different vehicle categories: cars, buses and trucks." },
          { p: "The higher the tax for a specific category, more and more electric vehicles of that category will pass through your village instead of internal combustion ones. Be aware that electric vehicles pay NO tax whatsoever, so they generate no revenue." },
          { p: "As internal combustion vehicles pass through, they generate harmful particles in the air that will clog your sky. You can remove these particles by buying one of two types of roof-installed air filters. They can be purchased using the tax revenues." },
          { p: "The game is basically a balance between taxing the vehicle categories and using the generated funds to acquire air filtration units that will clean up your skies." },
          {
            carousel: [
              "/software/unsmog-it/images/1.jpg",
              "/software/unsmog-it/images/2.jpg",
              "/software/unsmog-it/images/3.jpg",
              "/software/unsmog-it/images/4.jpg",
              "/software/unsmog-it/images/5.jpg",
            ]
          },
          { h3: "Endless Mode" },
          { p: "The game also has an endless mode, where there are no gameplay elements. It is provided as a fun looping animation to have running as a possible screensaver of sorts." },
          {
            carousel: [
              "/software/unsmog-it/images/6.jpg",
              "/software/unsmog-it/images/7.jpg",
              "/software/unsmog-it/images/8.jpg",
              "/software/unsmog-it/images/9.jpg",
              "/software/unsmog-it/images/10.jpg",
              "/software/unsmog-it/images/11.jpg",
              "/software/unsmog-it/images/12.jpg",
              "/software/unsmog-it/images/13.jpg",
              "/software/unsmog-it/images/14.jpg",
            ]
          },
          { h3: "Game Assets" },
          { p: "All of the game art was done by me in Blender, Photoshop, Substance 3D." },
          { p: "Some examples below:" },
          { embedded: "https://sketchfab.com/models/2f0b10a091e842a8adbab71b2a594f20/embed" },
          {
            carousel: [
              "/software/unsmog-it/images/bus_classic.jpg",
              "/software/unsmog-it/images/bus_electric.jpg",
              "/software/unsmog-it/images/electric_truck.jpg",
              "/software/unsmog-it/images/entrance.jpg",
              "/software/unsmog-it/images/filter_large.jpg",
              "/software/unsmog-it/images/filter_mini.jpg",
              "/software/unsmog-it/images/hatchback_01.jpg",
              "/software/unsmog-it/images/hatchback_02.jpg",
              "/software/unsmog-it/images/passenger_electric_01.jpg",
              "/software/unsmog-it/images/passenger_electric_02.jpg",
              "/software/unsmog-it/images/pedestrian.jpg",
              "/software/unsmog-it/images/sensor.jpg",
              "/software/unsmog-it/images/storefront.jpg",
            ]
          },
        ]
      },
      {
        longName: "Arena",
        shortName: "Games",
        link: "/software/arena",
        imagePath: "/software/arena/images/title.jpg",
        download: {
          buttonLabel: "Open game in new tab",
          path: "https://tudormaerean.ro/software/arena"
        },
        data: [
          { warn: "Please be aware that the game is quite large (100 Mb). Do not open the game over a mobile connection if you can avoid it!" },
          { h3: "Intro" },
          { p: "This project started as an idea for a team project, together with a couple of former colleagues. Unfortunately we didn't progress beyond the initial stages, so it's now in limbo." },
          { p: "The idea behind it was to have an arena where the player would control a squad of robots through high level commands. The robots would then try their best to implement those commands. The opposing AI teams would try and overcome the player's team in ever increasing levels of difficulty." },
          { p: "The main points of the project:" },
          {
            ol: [
              "Learn Unity and building a functional project in it.",
              "Refresh 3D skills like modelling, texturing, skinning, animation etc.",
            ]
          },
          { h3: "Game Assets" },
          { embedded: "https://sketchfab.com/models/6cf9fb669d6f4d9f8bf98dbd1ec43c7a/embed" },
          { embedded: "https://sketchfab.com/models/6e57fc75f88f412ab426ac9c34c9bee5/embed" },
          { embedded: "https://sketchfab.com/models/acd40eb25d6e47f4bc055e262f8d674f/embed" },
          { embedded: "https://sketchfab.com/models/9ae895fc8ee546da89c523fa95e2665a/embed" },
          {
            carousel: [
              "/software/arena/images/1.jpg",
              "/software/arena/images/2.jpg",
              "/software/arena/images/3.jpg",
              "/software/arena/images/4.jpg",
              "/software/arena/images/5.jpg",
              "/software/arena/images/6.jpg",
              "/software/arena/images/7.jpg",
            ]
          },
        ]
      },
      {
        longName: "Battle Knight",
        shortName: "Web-based Games",
        link: "/software/battle-knight",
        imagePath: "/software/battle-knight/images/title.jpg",
        download: {
          buttonLabel: "Open game in new tab",
          path: "https://tudormaerean.ro/software/battle-knight"
        },
        data: [
          { h3: "Intro" },
          { p: "This project is an entry to a competition to create web-based mobile games. It was created with a team of friends. I was mainly in-charge with the asset creation (3D art). Unfortunately we mismanaged our time and we weren't able to complete the game in time." },
          { p: "Competition requirements:" },
          {
            ol: [
              "48 hours to create the game.",
              "Should have a story element.",
              "Needs to be web-based and able to be run on mobile devices.",
            ]
          },
          { h3: "Details" },
          { p: "We used the Three.js library to create the 3D environment in the browser. The 3D art was created in Blender." },
        ],
      },
      {
        longName: "Game of Life",
        shortName: "React",
        link: "/software/game-of-life",
        imagePath: "/software/game-of-life/images/title.jpg",
        download: {
          buttonLabel: "Open game in new tab",
          path: "https://tudormaerean.ro/software/game-of-life"
        },
        data: [
          { h3: "Intro" },
          { p: "This project is just a quick implementation of an endless running Game of Life to be used as an animated background for React components, such as dialogs, cards, buttons etc." },
        ]
      },
    ]
  },
  {
    longName: "Embedded",
    shortName: "Embedded",
    hasProjects: "true",
    hasLink: "false",
    projects: [
      {
        longName: "Airsoft Device",
        shortName: "Robotics",
        link: "/embedded/device",
        imagePath: "/embedded/device/images/title.jpg",
        data: [
          { h3: "Intro" },
          { p: "A project that I built for a family friend who plays airsoft. He wanted a device that would be one of the goals of an airsoft match, which the teams would have to disable before the timer runs out. The device can have the timer, disabling code and sound volume configured. Once the timer has been started, the only way to stop or make any other configuration changes is through entering the correct combination." },
          { p: "The main points of the project:" },
          {
            ol: [
              "Simple, easy to setup and use.",
              "Fun looking design.",
              "Look as much as possible as a commercial product bought from a store."
            ]
          },
          { p: "How to use:" },
          {
            ol: [
              "The * key is used to cycle through the available mode (Timer, Code, Volume, On/Off).",
              "The # key is used as the Enter key.",
              "The Timer mode accepts any time between 1s and 99 minutes and 59s. The default is 30 minutes.",
              "The Code mode accepts any 4 digit combination (1000-9999). The default is 1234.",
              "The Volume mode accepts any value between 0 and 255 (0 - loudest, 255 - off).",
              "The On/Off mode is the main mode of the device. Once turned on, only number combination can be entered to switch it back to Off.",
              "All modes will show/play an error message/sound when an incorrect value is entered."
            ]
          },
          {
            carousel: [
              "/embedded/device/images/01.jpg",
              "/embedded/device/images/02.jpg",
              "/embedded/device/images/03.jpg",
            ]
          },
          { h3: "Hardware" },
          {
            ol: [
              "Adafruit Feather M0 Express",
              "Adafruit Music Maker Feather",
              "Adafruit Adafruit 0.56 4-Digit Display",
              "Adafruit Adafruit 0.54 Quad Alphanumeric Display",
              "Adafruit 3x4 Matrix Keypad",
              "Adafruit Speaker 3 inch 8 Ohm 1W Speakers",
              "Adafruit Adafruit Quad 2x2 Feather Board",
              "Powerbank"
            ]
          },
        ]
      },
      {
        longName: "Face Tracking Robotic Assistant",
        shortName: "Robotics",
        link: "/embedded/facepi",
        imagePath: "/embedded/facepi/images/title.jpg",
        data: [
          { h3: "Intro" },
          { p: "A project that I've wanted to do for a long time now, which is an interactive robot. I find robots that are static or just roam about are quite boring. So I've decided to create a personal assistant type robot." },
          { p: "The main points of the project:" },
          {
            ol: [
              "Learn to use face tracking from both the hardware and software points of view.",
              "Create a similar experience to Siri/Alexa/Google Assistant, by actually using one of their clients.",
              "Get into 3D resin printing to create all of the required structural parts."
            ]
          },
          {
            carousel: [
              // "/embedded/facepi/images/01.jpg",
              "/embedded/facepi/images/02.jpg",
              // "/embedded/facepi/images/03.jpg",
              "/embedded/facepi/images/04.jpg",
              "/embedded/facepi/images/05.jpg",
              // "/embedded/facepi/images/06.jpg",
              // "/embedded/facepi/images/07.jpg",
              // "/embedded/facepi/images/08.jpg",
              // "/embedded/facepi/images/09.jpg",
              // "/embedded/facepi/images/10.jpg",
              // "/embedded/facepi/images/11.jpg",
              // "/embedded/facepi/images/12.jpg",
              // "/embedded/facepi/images/13.jpg",
              // "/embedded/facepi/images/14.jpg",
              // "/embedded/facepi/images/15.jpg",
              "/embedded/facepi/images/16.jpg",
              // "/embedded/facepi/images/17.jpg",
              // "/embedded/facepi/images/18.jpg",
              // "/embedded/facepi/images/19.jpg",
              // "/embedded/facepi/images/20.jpg",
              // "/embedded/facepi/images/21.jpg",
              "/embedded/facepi/images/22.jpg",
              // "/embedded/facepi/images/23.jpg",
              // "/embedded/facepi/images/24.jpg",
              // "/embedded/facepi/images/25.jpg",
              // "/embedded/facepi/images/26.jpg",
              // "/embedded/facepi/images/27.jpg",
              // "/embedded/facepi/images/28.jpg",
              // "/embedded/facepi/images/29.jpg",
              // "/embedded/facepi/images/30.jpg",
              // "/embedded/facepi/images/31.jpg",
              // "/embedded/facepi/images/32.jpg",
              "/embedded/facepi/images/33.jpg",
              "/embedded/facepi/images/34.jpg",
            ]
          },
        ]
      },
      {
        longName: "RC Self Balancing Robot",
        shortName: "Robotics",
        link: "/embedded/rcsbr",
        imagePath: "/embedded/rcsbr/images/title.jpg",
        data: [
          { h3: "Intro" },
          { p: "A project I’ve been thinking about for a while now is a self balancing remote controlled robot. The concept is to have a 2 wheel self balancing robot, which can operate in 3 modes:" },
          {
            ol: [
              "An autonomous mode where it just travels randomly using the sonar to navigate and avoid obstacles.",
              "One were it’s just standing in place balancing and reacting to any changes.",
              "The last being remote controlled through a BT mobile app, just like any RC toy."
            ]
          },
          { p: "The 8×8 RGB LED matrix is there to relay information about its current status and most importantly just to look nice." },
          { h3: "CAD Design" },
          {
            carousel: [
              "/embedded/rcsbr/images/rcsbr_cad_slide_0.jpg",
              "/embedded/rcsbr/images/rcsbr_cad_slide_1.jpg",
              "/embedded/rcsbr/images/rcsbr_cad_slide_2.jpg",
              "/embedded/rcsbr/images/rcsbr_cad_slide_3.jpg",
              "/embedded/rcsbr/images/rcsbr_cad_slide_4.jpg",
              "/embedded/rcsbr/images/rcsbr_cad_slide_5.jpg",
              "/embedded/rcsbr/images/rcsbr_cad_slide_6.jpg",
              "/embedded/rcsbr/images/rcsbr_cad_slide_7.jpg",
            ]
          },
          { h3: "Project Description" },
          { p: "The brains will be provided by a small Arduino clone, the Pololu A* LV. The IMU in conjunction with the motor encoders will help it keep itself balanced and move around. The step-down regulator will make sure the motors and the electronics get a nice and steady current flow. One of my obsessions (maybe I should call it OCD?) is to have my projects as nicely and compactly packaged as possible without resorting to prohibitively expensive solutions. As such, I have designed an enclosure in Autodesk Inventor which helped a great deal to identify any physical interferences between the components, adjusting the robot’s layout and then exporting the parts as vector graphics used by a laser cutter machine." },
          { p: "Parts used:" },
          {
            ol: [
              "Adafruit Pro Trinket 5V → Pololu A* LV",
              "Pololu DRV8835 Dual Motor Driver Carrier",
              "Adafruit BNO055 IMU",
              "Sparkfun BT Silver Mate",
              "D24V50F5 Step-Down Regulator 5V, 5A",
              "Maxbotix LV EZ4 Sonar Sensor",
              "Olimex 8 x 8 RGB LED Matrix → Adafruit NeoPixel 8×8 Matrix",
              "2 x Pololu 100:1 Micro Metal Geared Motor",
              "Pololu 42 x 19 mm Wheels and Encoder Kit",
              "900 mAh 7.4V LiPo Battery and Charger",
              "Custom designed carrier board (Designed in Eagle CAD)",
              "Laser Cut Acrylic Enclosure (Designed in Autodesk Inventor)",
              "On/Off Toggle Switch",
              "12 x Aluminium Buerklin mounting cubes",
              "8 x 35mm, 4 x 20mm, 4 x 5mm Aluminium standoffs",
              "76 x  Nuts and bolts",
            ]
          },
          { p: "The enclosure pieces were cut for each version as the project progressed. Test fits of the assembly were performed with great results each time. You can see the progress below." },
          { h3: "Assembly Tests" },
          {
            carousel: [
              "/embedded/rcsbr/images/rcsbr_electronics_slide_0.jpg",
              "/embedded/rcsbr/images/rcsbr_electronics_slide_1.jpg",
              "/embedded/rcsbr/images/rcsbr_electronics_slide_2.jpg",
              "/embedded/rcsbr/images/rcsbr_electronics_slide_3.jpg",
              "/embedded/rcsbr/images/rcsbr_electronics_slide_4.jpg",
              "/embedded/rcsbr/images/rcsbr_electronics_slide_5.jpg",
              "/embedded/rcsbr/images/rcsbr_electronics_slide_6.jpg",
              "/embedded/rcsbr/images/rcsbr_electronics_slide_7.jpg",
              "/embedded/rcsbr/images/rcsbr_electronics_slide_8.jpg",
              "/embedded/rcsbr/images/rcsbr_electronics_slide_9.jpg",
            ]
          },
          { p: "I’m really happy with how it all came together. As a note to self, considering how many nuts and bolts (76!) are holding everything together, it’s a really good idea to have an electric screwdriver." },
          { h3: "Electronics" },
          { p: "Since I wasn’t happy with how the final circuit would look like on the Sparkfun solder-able breadboard, I decided to design my own carrier board for the circuit. This would allow me to arrange the parts as I wanted and try and have a semblance of order for the 22 power and IO wires connecting to the board. Learning Eagle was quite fun (but not that easy) and I look forward to designing some new boards in the future. However not everything went as I wanted with the 1st version and since I switched to the Pololu A*, I made a 3rd version (the 2nd hasn’t materialised)." },
          { p: "Below are some test visualizations of the carrier board (v1 and v3):" },
          {
            carousel: [
              "/embedded/rcsbr/images/rcsbr_photo_slide_0.jpg",
              "/embedded/rcsbr/images/rcsbr_photo_slide_1.jpg",
              "/embedded/rcsbr/images/rcsbr_photo_slide_2.jpg",
              "/embedded/rcsbr/images/rcsbr_photo_slide_3.jpg",
              "/embedded/rcsbr/images/rcsbr_photo_slide_4.jpg",
              "/embedded/rcsbr/images/rcsbr_photo_slide_5.jpg",
            ]
          },
          { p: "In the end, I decided to use headers and have the electronics mounted like on a breadboard instead of soldering them directly to the board as initially planned. This made the space inside the robot even tighter, as the headers add around 8mm (at least I managed to find some shorter ones, the standard is around 9-10mm). This makes it much easier to reuse the electronics if I ever need to. In the end, I have 5mm to mount the NeoPixel matrix, 6,4mm between the matrix plexi mount and the carrier board (for all of the wire connections) and 19mm for the electronics. The board was designed to be completely removable from the robot, along with all of the wires and electronics. Its design is also flexible enough and has all of the A* input/outputs available, as such the board can be reused in basically any project that makes use of any combination of the electronics used in this particular robot." },
          { p: "To be continued..." },
        ]
      },
      {
        longName: "Big Red Merge Button",
        shortName: "Embedded",
        link: "/embedded/redbutton",
        imagePath: "/embedded/redbutton/images/title.jpg",
        data: [
          { h3: "Intro" },
          { p: "One big red button for when one wants to be serious about merging their code!" },
          { p: "The big red button that one should not press lightly, first needs to be armed from the side safety switch..." },
          { h3: "Project Description" },
          { p: "Just a small fun project for my brother's birthday. It connects through a computer's USB port and basically emulates the ENTER key. So if the user arms the big red button from the safety switch, they can then use it to press ENTER!" },
          {
            carousel: [
              "/embedded/redbutton/images/01.jpg",
              "/embedded/redbutton/images/02.jpg",
              "/embedded/redbutton/images/03.jpg",
              "/embedded/redbutton/images/04.jpg",
              "/embedded/redbutton/images/05.jpg",
              "/embedded/redbutton/images/06.jpg",
              "/embedded/redbutton/images/07.jpg",
              "/embedded/redbutton/images/08.jpg",
            ]
          }
        ]
      },
      {
        longName: "SMS Controlled Thermostat",
        shortName: "Systems Control",
        link: "/embedded/smsct",
        imagePath: "/embedded/smsct/images/title.jpg",
        data: [
          { h3: "Intro" },
          { p: "This project came about from a combination of factors. A relative needed a way to warm up his vacation house before he even got there, instead of waiting for the entire weekend for that to happen. The other reason was my desire to learn new things, get involved with electronics I (was supposed to) have learned from my formal degree and a desire to start tinkering." },
          { p: "So, the answer was “obvious”: an SMS controlled thermostat using the Arduino as a platform, an Arduino GSM Shield and various other bits and bobs." },
          { h3: "Project Description" },
          { p: "As at the time I was still new at using Autodesk Inventor, I designed the project in 3D Studio Max, a program I was much more familiar with. Obviously, designing something in the virtual space first helps a lot with visualizing the final product and identifying any possible issues and problems before committing to a final design." },
          { p: "Having bought the needed materials, I started assembling the circuit which took remarkably little time and with no major problems." },
          { p: "After checking the electrical side of things was in order and everything worked as intended, I started working on the software side. I started building the code in small steps taking care of each component in turn, such as writing data to the LCD, reading temperature and humidity readings, turning the LCD backlighting and button LEDs on/off depending the user’s movements and finally the all important SMS send/receive code." },
          { p: "The plastic case turned to be a very close fit with not that much room to spare. Which again shows the usefulness of virtual design prior to building even a small project such as this!" },
          { h3: "Hardware" },
          {
            ol: [
              "Arduino Uno v3",
              "Arduino official GSM/GPRS shield",
              "Temperature and humidity sensor",
              "Relay module",
              "20 x 4 LCD /w backlighting",
              "PIR sensor",
              "4 x LED momentary push buttons",
              "Breadboard",
              "Regular plastic box from a hardware store",
              "Laser cut Plexiglas faceplate",
              "Lots of wires, resistors, screws, nuts and a power supply",
            ]
          },
          { h3: "Assembly" },
          {
            carousel: [
              "/embedded/smsct/images/smsct_0.jpg",
              "/embedded/smsct/images/smsct_1.jpg",
              "/embedded/smsct/images/smsct_2.jpg",
              "/embedded/smsct/images/smsct_3.jpg",
              "/embedded/smsct/images/smsct_4.jpg",
              "/embedded/smsct/images/smsct_5.jpg",
            ]
          },
          { h3: "Conclusions" },
          { p: "This project turned out to be quite challenging for me. Thinking back, it was quite ambitious as a first project and foray into the world of (hobbyist) embedded systems. This has taught me some valuable lessons in return:" },
          {
            ol: [
              "The project included more complex coding than you’d regularly find on Arduino and related forums.",
              "The electronics tend to warm up more than I anticipated which forced me to use an external temperature sensor (the small white box). Having the sensor inside the main box or even glued to the outside caused the readings to rise by at least 4-5 degrees Celsius. Not a good idea when your trying to build a thermostat!",
              "The i2c protocol is a blessing when it comes to wiring.",
              "Never leave the final wiring with breadboard connections as my project’s experiences with its final users can attest that’s a very bad idea!",
              "Always plan as much as possible ahead of time and try to design the entire project in any software package you have available.",
              "Try and find and fit your project as snuggly and as sturdily as you can in proper plastic/metal enclosures.",
              "Learn to use vector graphic software packages (such as Corel Draw) which allows you to design laser cut parts which can open up a whole new world of possibilities!",
            ]
          }
        ]
      },
      {
        longName: "NeoPixel Ring Clock",
        shortName: "Embedded",
        link: "/embedded/nprc",
        imagePath: "/embedded/nprc/images/title.jpg",
        data: [
          { h3: "Project Description" },
          { p: "This project started as an idea about creating an unusual desk clock. It’s based around Adafruit’s NeoPixel Rings, in 12 and 24 LED declination. They would be the main display, being used to relay the information to its user. The clock would be contained inside an acrylic tube and it would be able to move sideways around the desk by shifting its center of gravity. This would be achieved by having a mobile counterweight inside its body which would be rotated with a small DC motor and its movements controlled using an IMU (inertial measuring unit). Once the clock would hit an obstacle, it would detect it and start moving back. For this purpose I have also thought about creating a special support to allow the clock to move about in a consistent manner. The IMU would also be used in order to maintain the vertical orientation of the information displayed by the LEDs even while the clock is rotating. A couple of other small individual NeoPixels would be used as mood lighting and mode selection feedback." },
          { h3: "Design" },
          { p: "Here you can see some early design iterations of the clock itself and a first idea about its support:" },
          {
            carousel: [
              "/embedded/nprc/images/nprc_assembly_0.jpg",
              "/embedded/nprc/images/nprc_assembly_1.jpg",
              "/embedded/nprc/images/nprc_assembly_2.jpg",
              "/embedded/nprc/images/nprc_assembly_3.jpg",
              "/embedded/nprc/images/nprc_assembly_4.jpg",
              "/embedded/nprc/images/nprc_assembly_5.jpg",
            ]
          },
          { h3: "Assembly" },
          { p: "Finally, the assembly has been laser cut and fit tested. Everything seems to look good:" },
          {
            carousel: [
              "/embedded/nprc/images/nprc_design_0.jpg",
              "/embedded/nprc/images/nprc_design_1.jpg",
              "/embedded/nprc/images/nprc_design_2.jpg",
              "/embedded/nprc/images/nprc_design_3.jpg",
              "/embedded/nprc/images/nprc_design_4.jpg",
              "/embedded/nprc/images/nprc_design_5.jpg",
              "/embedded/nprc/images/nprc_design_6.jpg",
            ]
          },
          { h3: "To Be Continued" },
          { p: "The next step is to start gathering the electronics and start testing the circuit and the code." }
        ]
      }
    ]
  },
  {
    hidden: false,
    longName: "CAD/CGI",
    shortName: "CAD/CGI",
    hasProjects: "true",
    hasLink: "false",
    projects: [
      {
        longName: "Seat Leon",
        link: "/cadcgi/leon",
        imagePath: "/cadcgi/leon/images/title.jpg",
        data: [
          { h3: "Intro" },
          { p: "This is the 3D model that I created for my university graduation project (June 2007), a Seat Leon 1P. The model was made in Autodesk's 3D Studio Max and has around 103.000 triangles without any additional smoothing. It was all done by hand in around a month of evening and weekend work. The model is however still missing some additional details, such as wipers, logos and some interior details." },
          { h3: "3D Model" },
          { embedded: "https://sketchfab.com/models/2098ef2ad7a9492689b599699c570159/embed" },
          { h3: "Gallery" },
          {
            carousel: [
              "/cadcgi/leon/images/leon_0.jpg",
              "/cadcgi/leon/images/leon_1.jpg",
              "/cadcgi/leon/images/leon_2.jpg",
              "/cadcgi/leon/images/leon_3.jpg",
              "/cadcgi/leon/images/leon_4.jpg",
              "/cadcgi/leon/images/leon_5.jpg",
              "/cadcgi/leon/images/leon_6.jpg",
              "/cadcgi/leon/images/leon_7.jpg",
              "/cadcgi/leon/images/leon_8.jpg",
            ]
          },
          { h3: "Project Description" },
          { p: "The project was thought as an improvement over current car purchasing experiences, where future car owners don't have a real chance of studying their future purchases in more detail. Current car configurators on manufacturer websites are quite limited visually and do not allow proper visualisations of selected configurations. The Virtual Showroom project was created to adress these issues, allowing complete control over how the future car owner wants the car to look, from paint colors, to wheel rims and interior/exterior views." },
          {
            carousel: [
              "/cadcgi/leon/images/vs_0.jpg",
              "/cadcgi/leon/images/vs_1.jpg",
              "/cadcgi/leon/images/vs_2.jpg",
              "/cadcgi/leon/images/vs_3.jpg",
              "/cadcgi/leon/images/vs_4.jpg",
              "/cadcgi/leon/images/vs_5.jpg",
              "/cadcgi/leon/images/vs_6.jpg",
              "/cadcgi/leon/images/vs_7.jpg",
              "/cadcgi/leon/images/vs_8.jpg",
            ]
          }
        ]
      },
      {
        hidden: true,
        longName: "Game Assets",
        link: "/cadcgi/games",
        imagePath: "/cadcgi/games/images/title.jpg",
        data: [
          { h3: "Ballance" },
          { p: "This game..." },
          { h3: "Darts" },
          { p: "This game..." },
          {
            carousel: [

            ]
          },
          { h3: "Helicopter" },
          { p: "This game..." },
          {
            carousel: [

            ]
          },
          { h3: "Mobile Casino" },
          { p: "This game..." },
          {
            carousel: [

            ]
          },
          { h3: "Racer" },
          { p: "This game..." },
          {
            carousel: [

            ]
          },
          { h3: "Target Shooter" },
          { p: "This game..." },
          {
            carousel: [

            ]
          },
        ]
      },
      {
        hidden: true,
        longName: "Various",
        link: "/cadcgi/various",
        imagePath: "/cadcgi/various/images/title.jpg",
        data: [
          { h3: "Mountain House" },
          { p: "This..." },
          {
            carousel: [

            ]
          },
          { h3: "Elemental" },
          { p: "This..." },
          {
            carousel: [

            ]
          },
          { h3: "Park Scene" },
          { p: "This..." },
          {
            carousel: [

            ]
          },
        ]
      }
    ]
  }
];
