import React, { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Color from 'color';
import { makeStyles } from '@mui/styles';
import { useCoverCardMediaStyles } from '@mui-treasury/styles/cardMedia/cover';
import { Box } from '@mui/system';
import { Card, CardActionArea, CardMedia, Grid, Link, Skeleton, Typography } from '@mui/material';

const titleFontSize = '1.25rem';
const subtitleFontSize = '1rem';
const captionFontSize = '0.875rem';
const subtitleFamily = "'Spartan', sans-serif";
const family = "'Montserrat', sans-serif";

const useStyles = makeStyles(() => ({
  actionArea: {
    minHeight: 256,
    minWidth: 64,
    maxWidth: 256,
    maxHeight: 256,
    borderRadius: '1rem',
    transition: '0.2s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  card: ({ color }) => ({
    borderRadius: '1rem',
    boxShadow: 'none',
    position: 'relative',
    minHeight: 256,
    minWidth: 64,
    maxWidth: 256,
    maxHeight: 256,
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '64%',
      bottom: 0,
      zIndex: 1,
      background: 'linear-gradient(to top, #000, rgba(0,0,0,0))',
    },
    '&:hover': {
      boxShadow: `0 6px 12px 0 ${Color(color)
        .rotate(-12)
        .darken(0.2)
        .fade(0.5)
        }`,
    },
  }),
  content: {
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    width: '100%',
  },
}));

const useStylesInfo = makeStyles(() => ({
  title: {
    fontFamily: family,
    color: '#fff',
    fontSize: titleFontSize,
    fontWeight: 'bold',
    lineHeight: 1.2
  },
  subtitle: {
    fontFamily: subtitleFamily,
    color: 'rgba(255, 255, 255, 0.92)',
    fontSize: subtitleFontSize,
    lineHeight: 1.4,
    letterSpacing: '1px',
    fontWeight: 200
  },
  caption: {
    fontFamily: family,
    color: 'rgba(255, 255, 255, 0.72)',
    fontSize: captionFontSize,
    lineHeight: 1.5,
    '&:last-child': {
      marginTop: '1rem'
    }
  }
}));

export const ProjectCardV2 = ({ project }) => {
  const [image, setImage] = useState(false);

  const loadImage = useCallback(() => {
    import(`../../resources${project.imagePath}`).then(img => {
      setImage(img.default);
    });
  }, [project.imagePath]);

  useEffect(() => {
    loadImage();
  }, [loadImage]);

  const mediaStyles = useCoverCardMediaStyles({ bgPosition: 'top' });
  // dark blue: #a1d6e2 - purple: #4d137f - orange: #ff9900 - dark brown: #34241e
  const classes = useStyles({ color: '#cccccc' });
  const classesInfo = useStylesInfo();

  return (
    <>
      <Grid xs={6} sm={6} md={3} item>
        {image ? (
          <CardActionArea className={classes.actionArea}>
            <Link component={RouterLink} underline="none" to={project.link}>
              <Card className={classes.card}>
                <CardMedia
                  classes={mediaStyles}
                  image={image}
                />
                <Box py={3} px={2} className={classes.content}>
                  <Typography className={classesInfo.subtitle}>{project.shortName}</Typography>
                  <Typography className={classesInfo.title}>{project.longName}</Typography>
                  {/* <Typography className={classesInfo.caption}>Perfect for everyone</Typography> */}
                </Box>
              </Card>
            </Link>
          </CardActionArea>
        ) : (
          <Skeleton variant="rectangular" width={256} height={256} />
        )}
      </Grid>
    </>
  );
};

export default ProjectCardV2;
