import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { NoSsr } from '@mui/core';
import GoogleFontLoader from 'react-google-font-loader';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Header from './components/Header/Header';
import MainPage from './components/MainPage/MainPage';
import Page404 from "./components/Page404/Page404";
import Footer from "./components/Footer/Footer";
import Page from "./components/Page/Page";
import { setup } from "./resources/setup/setup";
import './App.css';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <NoSsr>
        <GoogleFontLoader
          fonts={[
            { font: 'Spartan', weights: [300] },
            { font: 'Keania One' },
            { font: 'Montserrat', weights: [200, 400, 700] },
          ]}
        />
      </NoSsr>
      <CssBaseline />

      <Router>
        <Header />

        <Switch>
          <Route exact path="/" component={MainPage} />
          {setup && setup.map(category => (
            category.projects.map(project => (
              <Route exact path={project.link} render={() => <Page project={project} />} />              
            ))
          ))}          
          <Route path="*" component={Page404} />
        </Switch>

        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
