import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Card, CardMedia, Dialog, Fab, MobileStepper, Skeleton } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box } from "@mui/system";

const useStyles = makeStyles(() => ({
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: 16
  },
  card: {
    height: 512,
    width: 512,
    borderRadius: '1rem',
    boxShadow: 'none',
    position: 'relative',
    "@media only screen and (max-width: 600px)": {
      // marginLeft: 8,
      // marginRight: 8,
      height: "100vw",
      width: "90vw",
    }
  },
  media: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  buttons: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  stepper: {
    position: "absolute",
    background: "none",
    maxWidth: "100%",
    justifyContent: "center",
  }
}));

export const Carousel = ({ data }) => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const loadImages = useCallback(() => {
    Promise.all(data.map(imageData => import(`../../resources${imageData}`))).then(imgResults => {
      const imgs = imgResults.map(img => img.default);
      setImages(imgs);
    });
  }, [data, setImages]);

  useEffect(() => {
    loadImages();
  }, [loadImages]);

  useEffect(() => {
    if (images.length === data.length && data.length > 0) {
      setSelectedImage(0);
      setShouldRender(true);
    }
  }, [images.length, data.length, setShouldRender]);

  const handleNext = () => {
    setSelectedImage((prev) => prev === images.length - 1 ? 0 : prev + 1);
  };

  const handleBack = () => {
    setSelectedImage((prev) => prev === 0 ? images.length - 1 : prev - 1);
  };

  const classes = useStyles();

  return (
    <>
      <Box className={classes.box}>
        {
          shouldRender ? (
            <Card className={classes.card}>
              <CardMedia
                onClick={() => setShowDialog(true)}
                component="img"
                className={classes.media}
                image={images[selectedImage]}
              />
              <span className={classes.buttons}>
                <Fab color="primary" aria-label="left" onClick={handleBack}>
                  <ChevronLeftIcon />
                </Fab>
                <Fab color="primary" aria-label="right" onClick={handleNext}>
                  <ChevronRightIcon />
                </Fab>
              </span>
              <MobileStepper
                className={classes.stepper}
                variant="dots"
                steps={images.length}
                activeStep={selectedImage}
                sx={{ maxWidth: 400, flexGrow: 1 }}
              />
            </Card>
          ) : (
            <Skeleton mt={1} mb={1} variant="rectangular" width={512} height={512} />
          )}
      </Box>
      <Dialog onClose={() => setShowDialog(false)} open={showDialog} maxWidth="xl">
        <CardMedia
          onClick={() => setShowDialog(false)}
          component="img"
          image={images[selectedImage]}
        />
      </Dialog>
    </>
  );
};

export default Carousel;
