import { AppBar, Box, Typography, Toolbar, Link, Avatar } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import logo from "../../resources/logo.png";

const Header = () => {
  return (
    <Box>
      <AppBar position="fixed">
        <Toolbar sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Typography align="center" variant="h6" color="inherit" component="div">
            <Link component={RouterLink} underline="none" to="/">
              Projects
            </Link>
          </Typography>
          <Box>
            <Avatar alt="logo" src={logo} />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;
