import React from "react";
import { makeStyles } from '@mui/styles';
import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";

const useStyles = makeStyles(() => ({
  footer: {
    padding: 32,
    // position: "sticky",
    bottom: 0
  }
}));

export const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Divider />
      <Box py={1} textAlign={{ xs: 'center', md: 'right' }}>
        <Typography
          component={'p'}
          variant={'caption'}
          color={'textSecondary'}
        >
          ©Tudor Maerean {new Date().getFullYear()} All rights reserved
        </Typography>
      </Box>
    </div>
  );
};

export default Footer;
