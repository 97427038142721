import React, { useCallback, useEffect } from "react";
import { makeStyles } from '@mui/styles';
import { Button, Container, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import WarningIcon from '@mui/icons-material/Warning';
import Carousel from "../Carousel/Carousel";

const useStyles = makeStyles(() => ({
  sectionTitle: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '1.75rem',
    fontWeight: 'bold',
    lineHeight: 1.2
  },
  warning: {
    color: "#ff2f00"
  },
  embedded: {
    marginTop: 10
  }
}));

export const Page = ({ project }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const classes = useStyles();

  const generateListItem = useCallback((listData, index) => (
    <ListItem key={index}>
      <ListItemIcon>
        <CircleOutlinedIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText primary={listData} />
    </ListItem>
  ), []);

  const renderSection = useCallback((section, index) => {
    const keyValue = Object.keys(section)[0];

    switch (keyValue) {
      case "h3":
        return <Typography key={index} mt={4} mb={1} className={classes.sectionTitle} variant="h3">{section[keyValue]}</Typography>;
      case "p":
        return <Typography mt={1} key={index}>{section[keyValue]}</Typography>;
      case "warn":
        return (
          <Stack mb={4} direction="row" justifyContent="flex-start" spacing={2}>
            <WarningIcon className={classes.warning} />
            <Typography key={index} className={classes.warning}>{section[keyValue]}</Typography>
          </Stack>
        );
      case "ol":
        return (
          <List key={index} dense={true}>
            {section[keyValue].map((listData, index) => generateListItem(listData, index))}
          </List>
        );
      case "carousel":
        return <Carousel key={index} data={section[keyValue]} />;
      case "embedded":
        return <iframe class={classes.embedded} width="100%" height="480" title={section[keyValue]} src={section[keyValue]} frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" onmousewheel=""></iframe>;
      default:
        return <div>Tag not found...</div>
    }
  }, [generateListItem, classes]);

  if (!project) {
    return null;
  }

  return (
    <Container style={{ paddingTop: 100 }}>
      <Stack mb={4} direction="row" justifyContent="space-between" spacing={2}>
        <Typography variant="h3">{project.longName}</Typography>
        {project.download && (
          <Button variant="contained" startIcon={<DownloadIcon />} href={project.download.path} target="_blank">
            {project.download.buttonLabel}
          </Button>
        )}
      </Stack>
      {project.data.map((section, index) => renderSection(section, index))}
    </Container>
  );
};

export default Page;
